








import Vue from "vue";
import { Component } from "vue-property-decorator";
import '@fortawesome/fontawesome-free/css/all.css'
import '@fortawesome/fontawesome-free/js/all.js'

@Component({})
export default class AppComponent extends Vue {}
